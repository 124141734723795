export default (id) => ({
    user: {
        email: null,
        first_name: null,
        last_name: null,
        phone: null,
    },
    async init() {
        console.log('init success', this.$parent.user, window.current.user);
        this.user = this.$parent.user;
        await window.googleAnalyticsManager.pushEvent(window.googleAnalyticsManager.events.QUOTE_SUBMITTED, {
            user_data : await window.googleAnalyticsManager.encodeAttributesToSHA256({
                email_address: this.user.email,
                phone_number: this.user.phone,
                first_name: this.user.first_name,
                last_name: this.user.last_name
            })
        });
    },
    isValid() {
        return true;
    }
});