

export default class GoogleAnalyticsManager {
    events = {
        QUOTE_STEP: "quote_step",
        QUOTE_SUBMITTED: "quote_submitted",
    };

    async encodeToSHA256(input) {
        const textAsBuffer = new TextEncoder().encode(input);
        const hashBuffer = await window.crypto.subtle.digest("SHA-256", textAsBuffer);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hash = hashArray
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");
        
        return hash;
    }

    async encodeAttributesToSHA256 (object) {
        for(let key in object) {
            object[key] = await this.encodeToSHA256(object[key]);
        }

        return object;
    }

    async pushEvent(eventName, data, sensitiveData) {
        let mergedObject = {
            event : eventName,
            ...data,
            ...await this.encodeAttributesToSHA256(sensitiveData)
        };

        window.dataLayer.push(mergedObject);
    }

}