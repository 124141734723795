/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");

import Toasteo from "toasteo";
window.Toasteo = new Toasteo({});

// Alpine JS components.
import quoteComponents from "./quotes/index";
import GoogleAnalyticsManager from "./GoogleAnalyticsManager.js";

window.app = {
  quotes: quoteComponents,
};

window.googleAnalyticsManager = new GoogleAnalyticsManager();

require("./helpers");

require("./alpine/register.js");
require("./alpine/lottie.js");
require("./alpine/facebook-login.js");

require("./alpine/search");
require("./alpine/search-quote-box");
require("./alpine/search-input");

require("./alpine/stars.js");

require("./alpine/user-avatar-form.js");
require("./alpine/conversation.js");
require("./alpine/image-modal.js");

require("./alpine/conversation-modal.js");
require("./alpine/professional-profile.js");
require("./alpine/rating-modal.js");
require("./alpine/project-modal.js");
require("./alpine/phone-modal.js");

require("./alpine/ad.js");

require("./alpine/categories.js");

require("./map-search.js");
