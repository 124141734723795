import GoogleAutocomplete from 'Base/classes/GoogleAutocomplete';
import Dispatch from 'Base/classes/Dispatch';
import Quote from 'Base/classes/Quote';

export default (id, location = '') => ({
    id: id,
    address: null,
    start_time_id: null,
    autocomplete: {
        google: new GoogleAutocomplete(),
        search: location,
        results: []
    },
    loading: false,
    init(startTimeId)
    {
        this.locationSearch();

        if(startTimeId)
        {
            this.start_time_id = startTimeId
        }
    },
    isValid()
    {
        return this.id && this.start_time_id && (this.address || this.prediction());
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step', {step: 2});
    },
    save()
    {
        window.googleAnalyticsManager.pushEvent(window.googleAnalyticsManager.events.QUOTE_STEP, {
            step_name : 'location-availabilty'
        });
        if ( this.address ) {
            this.nextStep();
        }
        
        this.setLocation(this.prediction())
            .then(() => {
                this.nextStep();
            });
    },
    nextStep()
    {
        new Dispatch(this, 'quote-next-step', {step: 2});

        new Quote(this.id).step2({
            start_time_id: this.start_time_id,
            address: this.address
        });
    },
    locationSearch()
    {
        this.autocomplete.google.search(this.autocomplete.search)
            .then(results => {
                this.autocomplete.results = results;
            })
            .catch(() => this.autocomplete.results = []);
    },
    prediction()
    {
        return (this.autocomplete.results.length > 0 && this.autocomplete.search.length > 2 ) ? this.autocomplete.results[0] : null;
    },
    setLocation(location)
    {
        if ( ! location ) {
            return;
        }

        return new Promise((resolve) => {
            location.details()
                .then((data) => {
                    this.address = data;
                    resolve();
                })
        });
    },
});