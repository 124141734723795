
import Dispatch from 'Base/classes/Dispatch';
import Quote from 'Base/classes/Quote';

export default (id) => ({
    id: id,
    start_reason_id: null,
    loading: false,
    init(startReasonId)
    {
        if(startReasonId){
            this.start_reason_id = startReasonId;
        }
    },
    isValid()
    {
        return this.start_reason_id && this.id;
    },
    back()
    {
        new Dispatch(this, 'quote-previous-step', {step: 1});
    },
    save()
    {
        window.googleAnalyticsManager.pushEvent(window.googleAnalyticsManager.events.QUOTE_STEP, {
            step_name : 'goal'
        });
        new Dispatch(this, 'quote-next-step', {step: 1});

        new Quote(this.id).step1({
            start_reason_id: this.start_reason_id
        });
    }
});